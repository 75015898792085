/* eslint-disable no-console */
import { Component, OnInit, inject } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoginService } from './login/services/companies-and-stores/login.service'
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { DialogComponent } from './shared/components/dialog/dialog.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { TranslationService } from '../app/shared/services/translation/translation.service'
import { HttpClient } from '@angular/common/http'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CommonService } from './shared/services/common/common.service'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { LoadingService } from './loaders/loading.service'
import { delay } from 'rxjs'
import { MatCardModule } from '@angular/material/card'
import { IconRegisterService } from './shared/services/icon-register/icon-register.service'
import { AuthService } from './services/auth.service'
import { DataService } from './shared/services/data/data.service'

@Component({
  selector: 'sarbo-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterModule, TranslateModule, MatProgressSpinnerModule, MatCardModule, NgIdleKeepaliveModule],
  providers: [TranslationService, Keepalive, HttpClient],
})
export class AppComponent implements OnInit {
  private translation = inject(TranslationService)
  private loginService = inject(LoginService)
  private dataService = inject(DataService)
  private authService = inject(AuthService)
  private commonService = inject(CommonService)
  private loadingService = inject(LoadingService)
  private dialog = inject(MatDialog)
  public translate = inject(TranslateService)
  dialogRef: MatDialogRef<DialogComponent> | undefined
  keepalive = inject(Keepalive)

  inactiveSessionTimeout = 30
  loading = false
  iconRegisterService = inject(IconRegisterService)

  constructor(private idle: Idle) {
    this.startIdleWatching()

    this.authService.isIdleWatching$.subscribe({
      next: (isIdleWatching) => {
        isIdleWatching ? this.startIdleWatching() : this.stopIdleWatching()
      },
    })

    this.iconRegisterService.registerIcons()
    idle.setIdle(60 * (this.inactiveSessionTimeout - 1))
    idle.setTimeout(60 * 1) // 1 minute

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    idle.onIdleStart.subscribe(() => {
      idle.clearInterrupts()
      this.openDialogIdle()
    })

    idle.onTimeout.subscribe(() => {
      this.timeout()
    })

    idle.onTimeoutWarning.subscribe((seconds) => {
      if (this.dialogRef?.componentInstance) {
        this.dialogRef.componentInstance.data = {
          iconUrl: '../assets/icons/history_toggle_off.svg',
          title: this.translate.instant('keepAlive.title'),
          message: `${this.translate.instant('keepAlive.messageOne')} ${seconds} ${this.translate.instant(
            'keepAlive.messageTwo',
          )}`,
          buttonText01: this.translate.instant('commonInformation.buttons.continue'),
          buttonStyle01: 'flat',
          buttonColor01: 'primary',
          buttonFocus01: true,
          buttonText02: this.translate.instant('commonInformation.buttons.logout'),
          buttonStyle02: 'stroked',
          buttonColor02: 'primary',
        }
      }
    })

    this.keepalive.interval(60 * 5)
    this.keepalive.onPing.subscribe(() => {
      this.loginService.refreshToken()
    })
  }

  ngOnInit(): void {
    this.loginService.isUserLoggedIn() ? this.startIdleWatching() : this.stopIdleWatching()
    this.dataService.updateSignals()
    this.loadingService.loadingSub.pipe(delay(0)).subscribe({
      next: (loading) => (this.loading = loading),
      error(err) {
        console.error('Had been an error: ', err)
      },
    })
    this.commonService.preventBackButton()
    this.translation.getLanguages()
  }

  async timeout(): Promise<void> {
    await this.authService.logout(async () => {}, true)
  }

  // METHODS FOR KEEP ALIVE //
  startIdleWatching(): void {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
    this.idle.watch()
    this.keepalive.start()
  }

  stopIdleWatching(): void {
    this.idle.stop()
    this.keepalive.stop()
  }

  openDialogIdle(): void {
    this.dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      height: '400px',
    })

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === this.translate.instant('commonInformation.buttons.logout')) {
        this.authService.logout()
      }
      if (result === this.translate.instant('commonInformation.buttons.continue')) {
        this.startIdleWatching()
      }
    })
  }
}
