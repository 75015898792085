import { Directive, ElementRef, Input, OnInit } from '@angular/core'
import { DataService } from '../services/data/data.service'

@Directive({
  selector: '[sarboPermissionsNeeded]',
  standalone: true,
})
export class PermissionBasedAccessDirective implements OnInit {
  @Input() sarboPermissionsNeeded: string[]

  constructor(private element: ElementRef, private dataService: DataService) {}

  ngOnInit(): void {
    this.checkPermissions()
  }

  private checkPermissions(): void {
    const tempPermissions = this.dataService.getItem('permissions')
    const userPermissions = tempPermissions ? JSON.parse(tempPermissions) : undefined

    if (!userPermissions || !this.sarboPermissionsNeeded) {
      this.element.nativeElement.classList.add('hidden')
    } else if (this.sarboPermissionsNeeded.length > 0) {
      const hasPermission = this.sarboPermissionsNeeded.some((x) => userPermissions.includes(x))
      if (!hasPermission) {
        this.element.nativeElement.classList.add('hidden')
      }
    }
  }
}
