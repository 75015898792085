<div class="flex w-full desktop:mx-auto">
  <div class="relative w-[128px]">
    <div class="fixed h-full p-6 pr-0">
      <sarbo-main-nav-bar></sarbo-main-nav-bar>
    </div>
  </div>

  <div class="w-11/12 p-6">
    <div class="flex justify-end">
      <sarbo-dropdown-menu></sarbo-dropdown-menu>
    </div>

    <div class="w-full">
      <router-outlet />
    </div>
  </div>
</div>
