import { Routes } from '@angular/router'
import { PermissionsEnum } from '../shared/models/permission-based-access.model'
import { authGuard } from '../shared/guards/permission.guard'

export const adminRoutes: Routes = [
  {
    path: 'currency',
    data: {
      breadcrumb: 'routes.admin.currencyOptions',
      permissionsNeeded: [
        PermissionsEnum.CURRENCY_VIEW,
        PermissionsEnum.DENOMINATION_VIEW,
        PermissionsEnum.EXCHNG_RATE_VIEW,
      ],
    },
    loadComponent: () => import('../admin/pages/currency/currency.component'),
  },
  {
    path: 'restaurant-administration',
    data: {
      breadcrumb: 'routes.admin.restaurantAdministration',
    },
    children: [
      {
        path: '',
        loadComponent: () => import('../admin/pages/restaurant-administration/restaurant-administration.component'),
      },
      {
        path: 'new-restaurant',
        data: {
          breadcrumb: 'routes.admin.newRestaurant',
        },
        loadComponent: () => import('../admin/pages/new-restaurant/new-restaurant.component'),
      },
    ],
  },
  {
    path: 'users',
    data: {
      breadcrumb: 'routes.admin.Users',
    },
    loadComponent: () => import('../shared/components/empty-state/empty-section.component'),
  },
  {
    path: 'sales',
    data: {
      breadcrumb: 'routes.admin.salesAdmin',
    },
    loadComponent: () => import('../shared/components/empty-state/empty-section.component'),
  },
  {
    path: 'inventories',
    data: {
      breadcrumb: 'routes.admin.inventoriesAdmin',
    },
    loadComponent: () => import('../shared/components/empty-state/empty-section.component'),
  },
  {
    path: 'users-admin',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.admin.usersAdministration',
      permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW],
    },
    loadComponent: () => import('../admin/components/users-admin/users-admin.component'),
    children: [
      {
        path: 'new-user',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.admin.newUser',
          altTitle: 'routes.admin.usersAdministration',
          permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW],
        },
        loadComponent: () => import('../admin/components/users-admin/user-details/user-details.component'),
      },
      {
        path: 'user-details',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.admin.userDetails',
          altTitle: 'routes.admin.usersAdministration',
          permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW],
        },
        loadComponent: () => import('../admin/components/users-admin/user-details/user-details.component'),
      },
      {
        path: 'user-edition',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.admin.userEdition',
          altTitle: 'routes.admin.usersAdministration',
          permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW],
        },
        loadComponent: () => import('../admin/components/users-admin/user-details/user-details.component'),
      },
    ],
  },
  {
    path: 'roles-permissions',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.admin.rolesAndPermissions',
      permissionsNeeded: [PermissionsEnum.ROLE_PERMISSION_VIEW],
    },
    loadComponent: () => import('../admin/components/roles-permissions/roles-permissions.component'),
  },
  {
    path: 'articles',
    data: {
      breadcrumb: 'routes.admin.articles',
      permissionsNeeded: [PermissionsEnum.ARTICLE_VIEW],
    },
    children: [
      {
        path: '',
        loadComponent: () => import('../admin/pages/articles/articles.component'),
      },
      {
        path: 'new-article',
        data: {
          breadcrumb: 'routes.admin.newArticle',
        },
        loadComponent: () => import('../admin/components/new-article/new-article.component'),
      },
      {
        path: 'edit-article',
        data: {
          breadcrumb: 'routes.admin.editArticle',
        },
        loadComponent: () => import('../admin/components/new-article/new-article.component'),
      },
    ],
  },

  {
    path: 'suppliers',
    data: {
      breadcrumb: 'routes.admin.suppliers',
      permissionsNeeded: [PermissionsEnum.SUPPLIERS_VIEW],
    },
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/suppliers/suppliers.component'),
      },
      {
        path: 'new-suppliers',
        data: {
          breadcrumb: 'routes.admin.newSupplier',
        },
        loadComponent: () => import('./pages/new-supplier/new-supplier.component'),
      },
      {
        path: 'edit-suppliers/:idParam',
        data: {
          breadcrumb: 'routes.admin.editSupplier',
        },
        loadComponent: () => import('./pages/new-supplier/new-supplier.component'),
      },
    ],
  },
  {
    path: 'products',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.inventory.products',
      permissionsNeeded: [PermissionsEnum.PRODUCT_VIEW, PermissionsEnum.RECIPES_VIEW],
    },
    children: [
      {
        path: '',
        loadComponent: () => import('../admin/pages/product/product.component'),
      },
      {
        path: 'new-product',
        data: {
          breadcrumb: 'routes.inventory.newProduct',
        },
        children: [
          {
            path: '',
            loadComponent: () => import('../admin/pages/new-product/new-product.component'),
          },
          {
            path: 'recipe-management',
            data: {
              breadcrumb: 'routes.inventory.recipeManagement',
            },
            loadComponent: () => import('../admin/pages/recipe-management/recipe-management.component'),
          },
        ],
      },
      {
        path: 'edit-product/:idParam',
        data: {
          breadcrumb: 'routes.inventory.editProduct',
        },
        loadComponent: () => import('../admin/pages/new-product/new-product.component'),
      },
    ],
  },
  {
    path: 'goals',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.goals.title',
      permissionsNeeded: [PermissionsEnum.QCR_GOALS_VIEW],
    },
    children: [
      {
        path: 'QCRGoals',
        data: {
          permissionsNeeded: [PermissionsEnum.QCR_GOALS_VIEW],
        },
        loadComponent: () => import('./components/goals/qcr-goals/qcr-goals.component'),
      },
    ],
  },
]
