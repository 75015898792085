/* eslint-disable no-console */
import { CommonModule } from '@angular/common'
import { Component, OnInit, inject, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { ActivatedRoute, RouterLinkActive, RouterLinkWithHref } from '@angular/router'
import { CdkMenuModule } from '@angular/cdk/menu'
import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { MainNavBarMenuComponent, MainNavBarMenuItem } from './main-nav-bar-menu/main-nav-bar-menu.component'
import { PermissionBasedAccessDirective } from '../../directives/permission-based-access.directive'
import { PermissionsEnum } from '../../models/permission-based-access.model'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'sarbo-main-nav-bar',
    templateUrl: './main-nav-bar.component.html',
    styleUrl: './main-nav-bar.component.scss',
    imports: [
        MatMenuModule,
        CommonModule,
        RouterLinkWithHref,
        RouterLinkActive,
        MatIconModule,
        CdkMenuModule,
        PermissionBasedAccessDirective,
        TranslateModule,
    ]
})
export class MainNavBarComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute)
  private overlay = inject(Overlay)
  translateService = inject(TranslateService)

  isAdminRoute = signal<boolean>(false)
  isSalesRoute = signal<boolean>(false)
  isInventoryRoute = signal<boolean>(false)
  isReportsRoute = signal<boolean>(false)

  permissionsEnum = PermissionsEnum

  salesMenuItems: MainNavBarMenuItem[] = [
    {
      text: 'routes.sales.pointOfSale',
      icon: 'location_white',
      permissionsNeeded: [PermissionsEnum.CONSOLIDATED_VIEW, PermissionsEnum.FISCAL_DOCUMENTS_VIEW],
      subitems: [
        {
          text: 'routes.sales.consolidated',
          routerLink: '/sales/point-of-sale/consolidated',
          icon: 'inventory_done',
          permissionsNeeded: [PermissionsEnum.CONSOLIDATED_VIEW],
        },
        {
          text: 'routes.sales.fiscalDocuments',
          routerLink: '/sales/point-of-sale/fiscal-documents',
          icon: 'book-deposits',
          permissionsNeeded: [PermissionsEnum.FISCAL_DOCUMENTS_VIEW],
        },
      ],
    },
    {
      text: 'routes.sales.cashRegister',
      icon: 'income',
      permissionsNeeded: [PermissionsEnum.CASH_RECONCILIATION_VIEW, PermissionsEnum.CASH_AUDIT_VIEW],
      subitems: [
        {
          text: 'routes.sales.cashReconciliations',
          routerLink: '/sales/cash-register/cash-reconciliation',
          icon: 'query_stats',
          permissionsNeeded: [PermissionsEnum.CASH_RECONCILIATION_VIEW],
        },
        {
          text: 'routes.sales.cashAudit',
          routerLink: '/sales/cash-audit',
          icon: 'book-accounts',
          permissionsNeeded: [PermissionsEnum.CASH_AUDIT_VIEW],
        },
      ],
    },
    {
      text: 'routes.sales.depositServices',
      icon: 'deposit',
      permissionsNeeded: [PermissionsEnum.DEPOSITS_VIEW],
      routerLink: '/sales/deposit-services',
    },
    {
      text: 'routes.sales.safeControl',
      icon: 'safe_control',
      permissionsNeeded: [PermissionsEnum.SAFE_CTRL_INFLOW_VIEW],
      routerLink: '/sales/safe-control',
    },
    {
      text: 'routes.sales.closure',
      icon: 'check',
      permissionsNeeded: [PermissionsEnum.SALES_CLOSURE_VIEW],
      routerLink: '/sales/closure',
    },
    {
      text: 'routes.sales.events',
      icon: 'eventconfig',
      permissionsNeeded: [PermissionsEnum.CUSTOM_ACCESS_EVENTS_AREA],
      routerLink: '/sales/events',
    },
  ]

  inventoryMenuItems: MainNavBarMenuItem[] = [
    {
      text: 'routes.inventory.movements',
      routerLink: '/inventory/movements',
      icon: 'swap_horiz',
      permissionsNeeded: [PermissionsEnum.MOVEMENTS_DAY_VIEW],
    },
    {
      text: 'routes.inventory.inventoryClosure',
      routerLink: '/inventory/inventory-closures',
      icon: 'folder_shared',
      permissionsNeeded: [PermissionsEnum.DAILY_INVENTORY_VIEW, PermissionsEnum.GENERAL_INVENTORY_VIEW],
    },
    {
      text: 'routes.inventory.purchases',
      routerLink: '/inventory/purchases',
      icon: 'add_card',
      permissionsNeeded: [PermissionsEnum.MANUAL_PURCHASE_VIEW],
    },
    {
      text: 'routes.inventory.transfers',
      routerLink: '/inventory/transfers',
      icon: 'request_quote',
      permissionsNeeded: [PermissionsEnum.RECEIVED_VIEW, PermissionsEnum.SENT_VIEW],
    },
    {
      text: 'routes.inventory.waste',
      routerLink: '/inventory/waste',
      icon: 'local_dining',
      permissionsNeeded: [PermissionsEnum.RAW_VIEW, PermissionsEnum.COMPLETE_VIEW],
    },
  ]

  adminMenuItems: MainNavBarMenuItem[] = [
    {
      text: 'routes.admin.users',
      icon: 'users',
      permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW, PermissionsEnum.ROLE_PERMISSION_VIEW],
      subitems: [
        {
          text: 'routes.admin.usersAdministration',
          routerLink: '/admin/users-admin',
          icon: 'users',
          permissionsNeeded: [PermissionsEnum.USER_ADMIN_VIEW],
        },
        {
          text: 'routes.admin.rolesAndPermissions',
          routerLink: '/admin/roles-permissions',
          icon: 'manage_accounts',
          permissionsNeeded: [PermissionsEnum.ROLE_PERMISSION_VIEW],
        },
      ],
    },
    {
      text: 'routes.admin.generalSettings',
      icon: 'settings',
      permissionsNeeded: [PermissionsEnum.CURRENCY_VIEW],
      subitems: [
        {
          text: 'routes.admin.currencyOptions',
          routerLink: '/admin/currency',
          icon: 'currency',
          permissionsNeeded: [PermissionsEnum.CURRENCY_VIEW],
        },
      ],
    },
    {
      text: 'routes.admin.salesAdmin',
      icon: 'sales_2b2c2c',
      permissionsNeeded: [],
      routerLink: '/admin/sales',
    },
    {
      text: 'routes.admin.restaurantAdministration',
      icon: 'store',
      permissionsNeeded: [PermissionsEnum.RESTAURANT_VIEW],
      routerLink: '/admin/restaurant-administration',
    },

    {
      text: 'routes.admin.inventoriesAdmin',
      icon: 'closure',
      permissionsNeeded: [PermissionsEnum.ARTICLE_VIEW, PermissionsEnum.SUPPLIERS_VIEW, PermissionsEnum.PRODUCT_VIEW],
      subitems: [
        {
          text: 'routes.admin.articles',
          routerLink: '/admin/articles',
          icon: 'food_bank',
          permissionsNeeded: [PermissionsEnum.ARTICLE_VIEW],
        },
        {
          text: 'routes.admin.suppliers',
          routerLink: '/admin/suppliers',
          icon: 'suppliers',
          permissionsNeeded: [PermissionsEnum.SUPPLIERS_VIEW],
        },
        {
          text: 'routes.inventory.products',
          routerLink: '/admin/products',
          icon: 'fastfood',
          permissionsNeeded: [PermissionsEnum.PRODUCT_VIEW, PermissionsEnum.RECIPES_VIEW],
        },
      ],
    },

    {
      text: 'routes.admin.qcrGoals',
      routerLink: '/admin/goals/QCRGoals',
      icon: 'qcr_goals',
      permissionsNeeded: [PermissionsEnum.QCR_GOALS_VIEW],
    },
  ]

  reportsMenuItems: MainNavBarMenuItem[] = [
    {
      text: 'routes.reports.title',
      icon: 'report',
      permissionsNeeded: [],
      routerLink: '/reports/reports',
    },
    {
      text: 'routes.reports.salesProjection',
      icon: 'sales_projection',
      permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
      routerLink: '/reports/sales-projection',
    },
  ]

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      this.isAdminRoute.set(url[0].path === 'admin')
      this.isSalesRoute.set(url[0].path === 'sales')
      this.isInventoryRoute.set(url[0].path === 'inventory')
      this.isReportsRoute.set(url[0].path === 'reports')
    })
  }

  openMainNavBarSelector(menuId: string, menuItems: MainNavBarMenuItem[]): void {
    const target = document.querySelector(menuId) as HTMLElement
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: 'end',
            originY: menuId !== '#adminMenuTrigger' ? 'top' : 'bottom',
            overlayX: 'start',
            overlayY: menuId !== '#adminMenuTrigger' ? 'top' : 'bottom',
          },
        ]),
    })
    const component = new ComponentPortal(MainNavBarMenuComponent)
    const componentRef = overlayRef.attach(component)
    componentRef.instance.menuItems = menuItems
    componentRef.instance.selectedMenuSubitem.subscribe(() => {
      overlayRef.detach()
    })
    overlayRef.backdropClick().subscribe(() => overlayRef.detach())
  }
}
