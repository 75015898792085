import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs'
import { ClosureInventoryWorkDate, TaskGroup, TaskTracker } from '../../models/task-tracker.model'

@Injectable({
  providedIn: 'root',
})
export class TaskTrackerService {
  updateTaskTracker = new BehaviorSubject<boolean>(false)
  tasks: BehaviorSubject<TaskTracker[]> = new BehaviorSubject<TaskTracker[]>([])
  updateTaskTracker$ = this.updateTaskTracker.asObservable()

  constructor(private readonly http: HttpClient) {}

  getTasks(): Observable<{ tasks: TaskTracker[]; groups: TaskGroup[] }> {
    return this.getConfig().pipe(
      switchMap((config) => {
        return this.getCompanyTasks().pipe(
          map((companyTasks) => {
            return {
              groups: config.groups,
              tasks: config.tasks.map((task) => {
                task.completed = companyTasks ? companyTasks.some((completed) => completed === task.name) : false
                return task
              }),
            }
          }),
          catchError((error) => {
            console.error('Error getting company tasks', error)
            return of(config)
          }),
        )
      }),
    )
  }

  getConfig(): Observable<{ tasks: TaskTracker[]; groups: TaskGroup[] }> {
    return this.http.get<{ tasks: TaskTracker[]; groups: TaskGroup[] }>(`${environment.taskTrackerUrl}/config`)
  }

  private getCompanyTasks(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.taskTrackerUrl}/company`)
  }

  getWorkDateInventoryClosure(): Observable<ClosureInventoryWorkDate> {
    return this.http.get<ClosureInventoryWorkDate>(`${environment.inventoryManagementUrl}/inventory-closure/work-date`)
  }
}
