import { ChangeDetectionStrategy, Component, Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { TOpenSnackBarInput } from '../../domain/types/snack.type'

@Injectable({ providedIn: 'root' })
@Component({
    selector: 'sarbo-snackbar',
    templateUrl: './snackbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatSnackBarModule]
})
export class SnackbarComponent {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, type: 'success' | 'error', duration: number): void {
    let colorSchema = ''
    if (type === 'success') {
      colorSchema = 'success-snackbar'
    } else if (type === 'error') {
      colorSchema = 'error-snackbar'
    }
    this._snackBar.open(message, action, { duration, panelClass: colorSchema, verticalPosition: 'top' })
  }

  /**
   * This method will replace to openSnackBar
   * @param {TOpenSnackBarInput} optionsSnackBar
   */
  openSnackBarWithAction(optionsSnackBar: TOpenSnackBarInput): void {
    let colorSchema = ''
    if (optionsSnackBar.type === 'success') {
      colorSchema = 'success-snackbar'
    } else if (optionsSnackBar.type === 'error') {
      colorSchema = 'error-snackbar'
    }
    this._snackBar.open(optionsSnackBar.message, optionsSnackBar.action, {
      duration: optionsSnackBar.duration,
      panelClass: colorSchema,
      verticalPosition: 'top',
    })
  }

  closeSnackBar(): void {
    this._snackBar.dismiss()
  }
}
