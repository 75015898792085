/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { DropdownPanel } from './dropdown-panel'
import { CommonModule } from '@angular/common'

@Component({
    selector: 'sarbo-dropdown-custom',
    imports: [CommonModule],
    templateUrl: './dropdown-custom.component.html'
})
export class DropdownCustomComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>
  @Output() closed = new EventEmitter<void>()
  @Input() isMultiple = false

  close(): void {
    if (!this.isMultiple) {
      this.closed.emit()
    }
  }
}
