// eslint-disable-next-line spaced-comment
import { ApplicationConfig, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core'
import { Router, provideRouter, withComponentInputBinding } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { routes } from './app.routes'
import { provideHttpClient, HttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core'
import { CustomTranslateLoader } from './loaders/translateLoader'
import { TranslationService } from '../app/shared//services/translation/translation.service'
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { DatePipe, registerLocaleData } from '@angular/common'
import { LoadingInterceptor } from './loaders/loading.interceptor'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import * as Sentry from '@sentry/angular'
import localePy from '@angular/common/locales/es'
import localeEn from '@angular/common/locales/en'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'

registerLocaleData(localeEn, 'en')
registerLocaleData(localePy, 'es')

export function initApp(translateService: TranslateService): () => Promise<{ [key: string]: string }> {
  translateService.use('en').toPromise()
  return () => translateService.get(['sales']).toPromise()
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const initializerFn = initApp(inject(TranslateService))
      return initializerFn()
    }),
    provideEnvironmentNgxMask(),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader,
          deps: [HttpClient, TranslationService],
        },
      }),
      MatNativeDateModule,
    ),
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    provideCharts(withDefaultRegisterables()),
  ],
}
