import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { DropdownMenuComponent } from '../../components/dropdown-menu/dropdown-menu.component'
import { MainNavBarComponent } from '../../components/main-nav-bar/main-nav-bar.component'

@Component({
  selector: 'sarbo-app-layout',
  templateUrl: './app-layout.component.html',
  imports: [CommonModule, RouterModule, DropdownMenuComponent, MainNavBarComponent],
})
export class AppLayoutComponent {}
