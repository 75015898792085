import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable, map } from 'rxjs'
import {
  CreateEditUser,
  DynamoUserCreation,
  DynamoUserEdition,
  Employee,
  Role,
  Store,
  User,
  UsersData,
} from '../models/users-admin.model'
import { Filter } from '../../shared/components/data-table/models/table-config.model'
import { DropdownItem } from '../../shared/components/dropdown/dropdown.component'
import { FilteringOptions } from '../components/users-admin/users-admin.component'

@Injectable({
  providedIn: 'root',
})
export class UsersAdminService {
  private http = inject(HttpClient)

  getUsersTableData(filteringOptions: FilteringOptions, page = 0): Observable<UsersData> {
    return this.http.post<UsersData>(environment.tmpUserManagement + '/users/filters', filteringOptions, {
      params: { page, size: 12 },
    })
  }

  getUsersTableStoresFilters(): Observable<Filter> {
    return this.http.get<Store[]>(environment.tmpUserManagement + '/users/stores').pipe(
      map((stores) => ({
        label: 'admin.usersAdministration.table.tableFilters.store',
        options: stores.map((store) => ({
          label: `${store.storeNumericCode} - ${store.storeName}`,
          value: store.storeId.toString(),
        })),
      })),
    )
  }

  getUsersTableRolesFilters(): Observable<Filter> {
    return this.http.get<Role[]>(environment.tmpUserManagement + '/users/roles').pipe(
      map((roles) => ({
        label: 'admin.usersAdministration.table.tableFilters.role',
        options: roles.map((role) => ({
          label: role.roleName,
          value: role.roleId.toString(),
        })),
      })),
    )
  }

  getSelectableStores(): Observable<DropdownItem[]> {
    return this.http.get<Store[]>(environment.sarboAgent + '/stores').pipe(
      map((stores) =>
        stores.map((store) => ({
          key: store.storeId.toString(),
          value: `${store.storeNumericCode} - ${store.storeName}`,
        })),
      ),
    )
  }

  getSelectableRoles(): Observable<DropdownItem[]> {
    return this.http.get<Role[]>(environment.tmpUserManagement + '/roles').pipe(
      map((roles) =>
        roles.map((role) => ({
          key: role.roleId.toString(),
          value: role.roleName,
        })),
      ),
    )
  }

  getAvailableEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(environment.tmpUserManagement + '/employees')
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.tmpUserManagement}/users/username`)
  }

  createUser(user: CreateEditUser): Observable<User> {
    return this.http.post<User>(environment.tmpUserManagement + '/users', user)
  }

  createUserInDynamo(user: DynamoUserCreation): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(environment.authUrl + '/create-user', user)
  }

  updateUserInDynamo(user: DynamoUserEdition): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(environment.authUrl + '/edit-user', user)
  }

  toggleUserStatus(userId: number, status: number): Observable<null> {
    return this.http.patch<null>(`${environment.tmpUserManagement}/users/${userId}/status/${status}`, null)
  }

  toggleUserStatusDynamo(username: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(environment.authUrl + '/status', { username })
  }

  updateUser(user: CreateEditUser, userId: number): Observable<User> {
    return this.http.patch<User>(`${environment.tmpUserManagement}/users/${userId}`, user)
  }
}
