export enum PermissionsEnum {
  // Existing permissions
  ROLE_PERMISSION_VIEW = 'ROLE_PERMISSION_VIEW',
  ROLE_PERMISSION_DEACTIVATE = 'ROLE_PERMISSION_DEACTIVATE',
  ROLE_PERMISSION_EDIT = 'ROLE_PERMISSION_EDIT',
  ROLE_PERMISSION_CREATE = 'ROLE_PERMISSION_CREATE',

  HOME = 'HOME',

  // SALES SECTION ///////////////////////////////////////////////////////////////////////////

  CASH_RECONCILIATION_EDIT = 'CASH_RECONCILIATION_EDIT',
  CASH_RECONCILIATION_VIEW = 'CASH_RECONCILIATION_VIEW',
  CASH_RECONCILIATION_CREATE = 'CASH_RECONCILIATION_CREATE',

  CASH_AUDIT_VIEW = 'CASH_AUDIT_VIEW',
  CASH_AUDIT_CREATE = 'CASH_AUDIT_CREATE',

  CONSOLIDATED_VIEW = 'CONSOLIDATED_VIEW',

  DEPOSITS_CREATE = 'DEPOSITS_CREATE',
  DEPOSITS_DEACTIVATE = 'DEPOSITS_DEACTIVATE',
  DEPOSITS_EDIT = 'DEPOSITS_EDIT',
  DEPOSITS_VIEW = 'DEPOSITS_VIEW',
  CASH_COLLECTION_VIEW = 'CASH_COLLECTION_VIEW',
  CASH_COLLECTION_CREATE = 'CASH_COLLECTION_CREATE',

  FISCAL_DOCUMENTS_VIEW = 'FISCAL_DOCUMENTS_VIEW',

  SALES_CLOSURE_CREATE = 'SALES_CLOSURE_CREATE',
  SALES_CLOSURE_VIEW = 'SALES_CLOSURE_VIEW',

  SAFE_CTRL_INFLOW_CREATE = 'SAFE_CTRL_INFLOW_CREATE',
  SAFE_CTRL_INFLOW_DEACTIVATE = 'SAFE_CTRL_INFLOW_DEACTIVATE',
  SAFE_CTRL_INFLOW_EDIT = 'SAFE_CTRL_INFLOW_EDIT',
  SAFE_CTRL_INFLOW_VIEW = 'SAFE_CTRL_INFLOW_VIEW',

  SAFE_CTRL_OUTFLOW_VIEW = 'SAFE_CTRL_OUTFLOW_VIEW',

  SAFE_CONTROL_CASH_AUDIT_SUBMIT = 'SAFE_CONTROL_CASH_AUDIT_SUBMIT',
  SAFE_CONTROL_CASH_AUDIT_VIEW = 'SAFE_CONTROL_CASH_AUDIT_VIEW',

  // CLOSURE > DELIVERY TAB > ADJUSTED / NON ADJUSTED ORDERS ///////////////////////////////////////////////////////////////////////
  ORDER_ADJUSTMENT_VIEW = 'ORDER_ADJUSTMENT_VIEW',
  ORDER_ADJUSTMENT_CREATE = 'ORDER_ADJUSTMENT_CREATE',
  ORDER_ADJUSTMENT_DEACTIVATE = 'ORDER_ADJUSTMENT_DEACTIVATE',

  // CLOSURE > DELIVERY TAB > MANUAL ADJUSTMENT ///////////////////////////////////////////////////////////////////////
  MANUAL_ADJUSTMENT_VIEW = 'MANUAL_ADJUSTMENT_VIEW',
  MANUAL_ADJUSTMENT_EDIT = 'MANUAL_ADJUSTMENT_EDIT',
  MANUAL_ADJUSTMENT_CREATE = 'MANUAL_ADJUSTMENT_CREATE',
  MANUAL_ADJUSTMENT_DEACTIVATE = 'MANUAL_ADJUSTMENT_DEACTIVATE',

  // MOVEMENTS SECTION ///////////////////////////////////////////////////////////////////////
  MOVEMENTS_DAY_VIEW = 'MOVEMENTS_DAY_VIEW',

  // INVENTORY SECTION ///////////////////////////////////////////////////////////////////////
  DAILY_INVENTORY_VIEW = 'DAILY_INVENTORY_VIEW',
  DAILY_INVENTORY_CREATE = 'DAILY_INVENTORY_CREATE',
  DAILY_INVENTORY_EDIT = 'DAILY_INVENTORY_EDIT',

  GENERAL_INVENTORY_VIEW = 'GENERAL_INVENTORY_VIEW',
  GENERAL_INVENTORY_CREATE = 'GENERAL_INVENTORY_CREATE',
  GENERAL_INVENTORY_EDIT = 'GENERAL_INVENTORY_EDIT',

  // PURCHASES SECTION ///////////////////////////////////////////////////////////////////////
  MANUAL_PURCHASE_VIEW = 'MANUAL_PURCHASE_VIEW',
  MANUAL_PURCHASE_CREATE = 'MANUAL_PURCHASE_CREATE',
  MANUAL_PURCHASE_EDIT = 'MANUAL_PURCHASE_EDIT',
  MANUAL_PURCHASE_DEACTIVATE = 'MANUAL_PURCHASE_DEACTIVATE',

  // TRANSFERS SECTION /////
  RECEIVED_VIEW = 'RECEIVED_VIEW',
  RECEIVED_EDIT = 'RECEIVED_EDIT',
  RECEIVED_DEACTIVATE = 'RECEIVED_DEACTIVATE',
  SENT_VIEW = 'SENT_VIEW',
  SENT_CREATE = 'SENT_CREATE',
  SENT_EDIT = 'SENT_EDIT',
  SENT_DEACTIVATE = 'SENT_DEACTIVATE',

  // PRODUCTS SECTION ///////////////////////////////////////////////////////////////////////
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  PRODUCT_CREATE = 'PRODUCT_CREATE',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_DEACTIVATE = 'PRODUCT_DEACTIVATE',
  RECIPES_VIEW = 'RECIPES_VIEW',
  RECIPES_CREATE = 'RECIPES_CREATE',
  RECIPES_EDIT = 'RECIPES_EDIT',
  RECIPES_DEACTIVATE = 'RECIPES_DEACTIVATE',

  // WASTE SECTION ///////////////////////////////////////////////////////////////////////
  RAW_VIEW = 'RAW_VIEW',
  RAW_CREATE = 'RAW_CREATE',
  RAW_EDIT = 'RAW_EDIT',
  RAW_DEACTIVATE = 'RAW_DEACTIVATE',
  COMPLETE_VIEW = 'COMPLETE_VIEW',
  COMPLETE_CREATE = 'COMPLETE_CREATE',
  COMPLETE_EDIT = 'COMPLETE_EDIT',
  COMPLETE_DEACTIVATE = 'COMPLETE_DEACTIVATE',

  // ADMIN SECTION ///////////////////////////////////////////////////////////////////////////
  QCR_GOALS_VIEW = 'QCR_GOALS_VIEW',
  QCR_GOALS_EDIT = 'QCR_GOALS_EDIT',
  QCR_GOALS_CREATE = 'QCR_GOALS_CREATE',

  CURRENCY_VIEW = 'CURRENCY_VIEW',
  CURRENCY_CREATE = 'CURRENCY_CREATE',
  CURRENCY_EDIT = 'CURRENCY_EDIT',
  CURRENCY_DEACTIVATE = 'CURRENCY_DEACTIVATE',

  EXCHNG_RATE_VIEW = 'EXCHNG_RATE_VIEW',
  EXCHNG_RATE_CREATE = 'EXCHNG_RATE_CREATE',
  EXCHNG_RATE_DEACTIVATE = 'EXCHNG_RATE_DEACTIVATE',
  EXCHNG_RATE_EDIT = 'EXCHNG_RATE_EDIT',

  DENOMINATION_VIEW = 'DENOMINATION_VIEW',
  DENOMINATION_CREATE = 'DENOMINATION_CREATE',
  DENOMINATION_EDIT = 'DENOMINATION_EDIT',
  DENOMINATION_DEACTIVATE = 'DENOMINATION_DEACTIVATE',

  ARTICLE_VIEW = 'ARTICLE_VIEW',
  ARTICLE_CREATE = 'ARTICLE_CREATE',
  ARTICLE_EDIT = 'ARTICLE_EDIT',
  ARTICLE_DEACTIVATE = 'ARTICLE_DEACTIVATE',

  SUPPLIERS_VIEW = 'SUPPLIERS_VIEW',
  SUPPLIERS_CREATE = 'SUPPLIERS_CREATE',
  SUPPLIERS_EDIT = 'SUPPLIERS_EDIT',
  SUPPLIERS_DEACTIVATE = 'SUPPLIERS_DEACTIVATE',

  USER_ADMIN_VIEW = 'USER_ADMIN_VIEW',
  USER_ADMIN_DEACTIVATE = 'USER_ADMIN_DEACTIVATE',
  USER_ADMIN_CREATE = 'USER_ADMIN_CREATE',
  USER_ADMIN_EDIT = 'USER_ADMIN_EDIT',

  RESTAURANT_VIEW = 'RESTAURANT_VIEW',
  RESTAURANT_EDIT = 'RESTAURANT_EDIT',
  RESTAURANT_CREATE = 'RESTAURANT_CREATE',
  RESTAURANT_DEACTIVATE = 'RESTAURANT_DEACTIVATE',

  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_DEACTIVATE = 'EMPLOYEE_DEACTIVATE',

  // REPORTS SECTION ///////////////////////////////////////////////////////////////////////
  REPORTS_VIEW = 'REPORTS_VIEW',
  REPORTS_CREATE = 'REPORTS_CREATE',
  REPORTS_EDIT = 'REPORTS_EDIT',
  REPORTS_DEACTIVATE = 'REPORTS_DEACTIVATE',

  SALES_PROJECTIONS_VIEW = 'SALES_PROJECTIONS_VIEW',
  SALES_PROJECTIONS_VOTE = 'SALES_PROJECTIONS_VOTE',
  SALES_PROJECTIONS_EDIT = 'SALES_PROJECTIONS_EDIT',
  SALES_PROJECTIONS_SPECIAL = 'SALES_PROJECTIONS_SPECIAL',

  // CUSTOM ACCESS PERMISSIONS ///////////////////////////////////////////////////////////////////////
  CUSTOM_ACCESS_EVENTS_AREA = 'CUSTOM_ACCESS_EVENTS_AREA',
}
