import { inject } from '@angular/core'
import { CanActivateFn, ActivatedRouteSnapshot, Router } from '@angular/router'
import { DataService } from '../services/data/data.service'

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const dataService = inject(DataService)
  const router = inject(Router)

  const token = dataService.getItem('authToken')
  const userPermissions = JSON.parse(dataService.getItem('permissions'))

  const permissionsNeeded: string[] = route.data['permissionsNeeded']

  if (!token) {
    return router.parseUrl('/')
  } else if (!userPermissions || !permissionsNeeded) {
    return router.parseUrl('/home')
  } else if (permissionsNeeded.length > 0) {
    const hasPermission = permissionsNeeded.some((x) => userPermissions.includes(x))
    if (!hasPermission) {
      return router.parseUrl('/home')
    } else {
      return true
    }
  } else {
    return true
  }
}
