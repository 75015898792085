<div class="w-24 h-full">
  <nav class="flex flex-col items-center bg-darkcolor rounded-3xl h-full">
    <div class="h-10 w-10 mt-7 flex justify-center">
      <img src="../../../../assets/icons/sarbo.svg" alt="Logo" />
    </div>

    <div class="h-full w-full flex flex-col justify-center gap-4 -mt-16">
      <a class="flex flex-col text-center items-center" routerLink="/home">
        <img
          class="rounded-[8px] p-3 hover:bg-primary"
          src="../../../../assets/icons/home.svg"
          alt="Home Icon"
          routerLinkActive="bg-primary"
        />
        <span class="text-white text-xs font-normal mt-1">{{ 'routes.home' | translate }}</span>
      </a>

      <a
        [sarboPermissionsNeeded]="[
          permissionsEnum.CONSOLIDATED_VIEW,
          permissionsEnum.FISCAL_DOCUMENTS_VIEW,
          permissionsEnum.CASH_RECONCILIATION_VIEW,
          permissionsEnum.DEPOSITS_VIEW,
          permissionsEnum.SAFE_CTRL_INFLOW_VIEW,
          permissionsEnum.SALES_CLOSURE_VIEW
        ]"
        class="flex flex-col items-center gap-1"
        id="salesMenuTrigger"
        (click)="openMainNavBarSelector('#salesMenuTrigger', salesMenuItems)"
      >
        <img
          class="rounded-[8px] p-2 hover:bg-primary"
          src="../../../../assets/icons/sales.svg"
          alt="Sales Icon"
          [ngClass]="{ 'bg-primary': isSalesRoute() }"
        />
        <span class="text-white text-xs font-normal">{{ 'routes.sales.title' | translate }}</span>
      </a>

      <a
        [sarboPermissionsNeeded]="[
          permissionsEnum.MOVEMENTS_DAY_VIEW,
          permissionsEnum.DAILY_INVENTORY_VIEW,
          permissionsEnum.GENERAL_INVENTORY_VIEW,
          permissionsEnum.MANUAL_PURCHASE_VIEW,
          permissionsEnum.RECEIVED_VIEW,
          permissionsEnum.SENT_VIEW,
          permissionsEnum.PRODUCT_VIEW,
          permissionsEnum.RECIPES_VIEW,
          permissionsEnum.RAW_VIEW,
          permissionsEnum.COMPLETE_VIEW
        ]"
        class="flex flex-col items-center gap-1"
        id="inventoryMenuTrigger"
        (click)="openMainNavBarSelector('#inventoryMenuTrigger', inventoryMenuItems)"
      >
        <img
          class="rounded-[8px] p-2 hover:bg-primary"
          src="../../../../assets/icons/inventory.svg"
          alt="Inventory Icon"
          [ngClass]="{ 'bg-primary': isInventoryRoute() }"
        />
        <span class="text-white text-xs font-normal">{{ 'routes.inventory.title' | translate }}</span>
      </a>

      <a
        [sarboPermissionsNeeded]="[
          permissionsEnum.USER_ADMIN_VIEW,
          permissionsEnum.ROLE_PERMISSION_VIEW,
          permissionsEnum.CURRENCY_VIEW,
          permissionsEnum.DENOMINATION_VIEW,
          permissionsEnum.EXCHNG_RATE_VIEW,
          permissionsEnum.ARTICLE_VIEW
        ]"
        class="flex flex-col items-center gap-1"
        id="adminMenuTrigger"
        (click)="openMainNavBarSelector('#adminMenuTrigger', adminMenuItems)"
      >
        <img
          class="rounded-[8px] p-2 hover:bg-primary"
          src="../../../../assets/icons/admin.svg"
          alt="Admin Icon"
          [ngClass]="{ 'bg-primary': isAdminRoute() }"
        />
        <span class="text-white text-xs font-normal">{{ 'routes.admin.title' | translate }}</span>
      </a>

      <a
        class="flex flex-col text-center items-center"
        routerLink="/employees"
        [sarboPermissionsNeeded]="[permissionsEnum.EMPLOYEE_VIEW]"
      >
        <img
          class="rounded-[8px] p-3 hover:bg-primary"
          src="../../../../assets/icons/employees.svg"
          alt="Employees Icon"
          routerLinkActive="bg-primary"
        />
        <span class="text-white text-xs font-normal mt-1">{{ 'routes.employees.title' | translate }}</span>
      </a>

      <a
        class="flex flex-col text-center items-center"
        id="reportsMenuTrigger"
        (click)="openMainNavBarSelector('#reportsMenuTrigger', reportsMenuItems)"
        [sarboPermissionsNeeded]="[permissionsEnum.SALES_PROJECTIONS_VIEW]"
      >
        <img
          class="rounded-[8px] p-3 hover:bg-primary"
          src="../../../../assets/icons/reports.svg"
          alt="Reports Icon"
          [ngClass]="{ 'bg-primary': isReportsRoute() }"
        />
        <span class="text-white text-xs font-normal mt-1">{{ 'routes.reports.title' | translate }}</span>
      </a>
    </div>
  </nav>
</div>
