import { Routes } from '@angular/router'
import { authGuard } from '../shared/guards/permission.guard'
import { PermissionsEnum } from '../shared/models/permission-based-access.model'
import { CanDeactivateGuard } from './guards/deactivate.guard'

export const salesRoutes: Routes = [
  {
    path: 'point-of-sale',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.pointOfSale',
      permissionsNeeded: [PermissionsEnum.CONSOLIDATED_VIEW, PermissionsEnum.FISCAL_DOCUMENTS_VIEW],
    },
    children: [
      {
        path: 'consolidated',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.sales.consolidated',
          permissionsNeeded: [PermissionsEnum.CONSOLIDATED_VIEW],
        },
        loadComponent: () => import('../sales/components/consolidated/consolidated.component'),
      },
      {
        path: 'fiscal-documents',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.sales.fiscalDocuments',
          permissionsNeeded: [PermissionsEnum.FISCAL_DOCUMENTS_VIEW],
        },
        loadComponent: () => import('../sales/components/table-tax-document/table-tax-document.component'),
      },
    ],
  },
  {
    path: 'cash-register',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.cashRegister',
      permissionsNeeded: [PermissionsEnum.CASH_RECONCILIATION_VIEW],
    },
    children: [
      {
        path: 'cash-reconciliation',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.sales.cashReconciliations',
          permissionsNeeded: [PermissionsEnum.CASH_RECONCILIATION_VIEW],
        },
        loadComponent: () => import('../sales/components/cash-reconciliation/cash-reconciliation.component'),
      },
    ],
  },

  {
    path: 'cash-audit',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.cashAudit',
      permissionsNeeded: [PermissionsEnum.CASH_AUDIT_VIEW],
    },
    loadComponent: () => import('./components/cash-audit/cash-audit.component'),
    children: [
      {
        path: 'create-cash-audit',
        canActivate: [authGuard],
        canDeactivate: [CanDeactivateGuard],
        providers: [CanDeactivateGuard],
        data: {
          breadcrumb: 'routes.sales.newCashAudit',
          altTitle: 'routes.sales.cashAudit',
          permissionsNeeded: [PermissionsEnum.CASH_AUDIT_CREATE],
        },
        loadComponent: () => import('../sales/components/cash-audit/temp-cash-audit/temp-cash-audit.component'),
      },
      {
        path: 'view-cash-audit',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.sales.registeredCashAudit',
          altTitle: 'routes.sales.cashAudit',
          permissionsNeeded: [PermissionsEnum.CASH_AUDIT_VIEW],
        },
        loadComponent: () => import('../sales/components/cash-audit/temp-cash-audit/temp-cash-audit.component'),
      },
      {
        path: 'safe-control',
        canActivate: [authGuard],
        canDeactivate: [CanDeactivateGuard],
        providers: [CanDeactivateGuard],
        data: {
          breadcrumb: 'routes.sales.safeControlCashAudit',
          altTitle: 'routes.sales.cashAudit',
          permissionsNeeded: [PermissionsEnum.CASH_AUDIT_CREATE],
        },
        loadComponent: () => import('./components/safe-control-cash-audit/safe-control-cash-audit.component'),
      },
      {
        path: 'safe-control/:id',
        canActivate: [authGuard],
        data: {
          breadcrumb: 'routes.sales.safeControlCashAudit',
          altTitle: 'routes.sales.cashAudit',
          permissionsNeeded: [PermissionsEnum.CASH_AUDIT_VIEW],
        },
        loadComponent: () => import('./components/safe-control-cash-audit/safe-control-cash-audit.component'),
      },
    ],
  },

  {
    path: 'deposit-services/cash-collection',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.depositServicesCash',
      permissionsNeeded: [PermissionsEnum.DEPOSITS_VIEW],
      tab: 'cash-collection',
    },
    loadComponent: () => import('../sales/components/deposits-container/deposits/deposits.component'),
  },
  {
    path: 'deposit-services',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.depositServices',
      permissionsNeeded: [PermissionsEnum.DEPOSITS_VIEW],
    },
    loadComponent: () => import('../sales/components/deposits-container/deposits/deposits.component'),
  },
  {
    path: 'safe-control',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.safeControl',
      permissionsNeeded: [PermissionsEnum.SAFE_CTRL_INFLOW_VIEW],
    },
    loadComponent: () => import('../sales/components/safe-control/base-safe-control.component'),
  },
  {
    path: 'closure',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.closure',
      permissionsNeeded: [PermissionsEnum.SALES_CLOSURE_VIEW],
    },
    loadComponent: () => import('../sales/components/closure/closing-sales.component'),
  },
  {
    path: 'events',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.sales.events',
      permissionsNeeded: [PermissionsEnum.CUSTOM_ACCESS_EVENTS_AREA],
    },
    loadComponent: () => import('../shared/components/empty-state/empty-section.component'),
  },
  {
    path: 'cash-collection',
    data: {
      breadcrumb: 'routes.sales.cashCollection',
      tab: 'cash-collection',
      permissionsNeeded: [PermissionsEnum.CASH_COLLECTION_VIEW],
    },
    loadComponent: () => import('./components/deposits-container/deposits/deposits.component'),
  },
]
