import { Routes } from '@angular/router'
import { PermissionsEnum } from '../shared/models/permission-based-access.model'
import { authGuard } from '../shared/guards/permission.guard'
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard'

export const reportsRoutes: Routes = [
  {
    path: 'reports',
    loadComponent: () => import('./components/report/report-analytics.component'),
    data: {
      breadcrumb: 'routes.sales.report',
    },
  },
  { 
    path: 'sales-projection',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.reports.salesProjection',
      permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
    },
    children: [
      {
        path: '',
        canActivate: [authGuard],
        data: {
          permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
        },
        loadComponent: () => import('./pages/sales-projection-history/sales-projection-history.component'),
      },
      {
        path: 'view',
        canActivate: [authGuard],
        data: {
          permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
        },
        loadComponent: () => import('./pages/sales-projection-view/sales-projection-view.component'),
      },
      {
        path: 'edit-projection',
        canActivate: [authGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'routes.reports.editSalesProjection',
          permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_EDIT],
        },
        loadComponent: () => import('./components/edit-monthly-projection/edit-monthly-projection.component'),
      },
    ],
  },
]
