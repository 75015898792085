import { Routes } from '@angular/router'

export const employeesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('../employees/pages/employees/employees.component'),
  },
  {
    path: 'new-employee',
    data: {
      breadcrumb: 'routes.employees.newEmployee',
    },
    loadComponent: () => import('../employees/components/new-employees/new-employees.component'),
  },
  {
    path: 'edit-employee',
    data: {
      breadcrumb: 'routes.employees.editEmployee',
    },
    loadComponent: () => import('../employees/components/new-employees/new-employees.component'),
  },
]
