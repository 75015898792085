import { Component, Inject } from '@angular/core'
import { ButtonComponent } from '../button/button.component'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'

export type DialogData = {
  iconUrl?: string | null
  title: string
  message: string
  buttonText01: string | null
  buttonColor01: 'primary' | 'accent' | 'warn' | ''
  buttonStyle01: 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab'
  componentClass01?: string | undefined
  buttonFocus01?: boolean | undefined
  buttonText02: string | null
  buttonColor02: 'primary' | 'accent' | 'warn' | ''
  buttonStyle02: 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab'
  componentClass02?: string | undefined
  buttonFocus02?: boolean | undefined
}

@Component({
    selector: 'sarbo-dialog',
    templateUrl: './dialog.component.html',
    imports: [ButtonComponent, MatDialogModule]
})
export class DialogComponent {
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
